import React, { Component, useState, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import "../../App.scss";
//import Kerja from "../../assets/img/kerjasama.svg";
import Kerja from "../../assets/img/kerjasama.co.id.vertical.png";

import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Input,
  Label,
  List,
  Accordion,
} from "semantic-ui-react";

export default class Faq extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.isLoading = true;
  }

  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        <Navbar />
        <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Row className="justify-content-md-center">
            <Col xs lg="12">
              <div class="visi-misi text-center">
                <h4
                  style={{
                    fontSize: "30px",
                    fontWeight: "700",
                    color: "#033C85",
                  }}
                >
                  Faq
                </h4>
              </div>

              <Accordion
                fluid
                styled
                style={{ marginTop: "50px", fontFamily: "Helvetica" }}
              >
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  1. Apa yang dimaksud sistem investasi bisnis gotong royong
                  (Securities Crowdfunding) di Kerjasama.co.id ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Securities Crowdfunding</strong> merupakan layanan
                    urun dana melalui penawaran efek berbasis teknologi
                    informasi, yang dapat memfasilitasi masyarakat berinvestasi
                    ke Bisnis UKM, melalui berbagai jenis efek seperti Saham,
                    Bond (Obligasi),{/* dan Sukuk (Syariah)*/} melalui sistem
                    urun dana penawaran saham berbasis Teknologi Informasi yang
                    sudah diatur oleh <strong>Peraturan POJK 57/2020</strong>.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  2. Apa yang dimaksud dengan efek ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Efek</strong> adalah surat berharga, yaitu, surat
                    berharga komersial, saham, obligasi, unit penyertaan kontrak
                    investasi kolektif, kontrak berjangka atas Efek, dan setiap
                    derivatif dari Efek. Dalam hal layanan Securities
                    Crowdfunding di Kerjasama.co.id, efek tersebut mencakup
                    Saham, Obligasi{/* dan Sukuk*/}.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  3. Bagaimana system bisnis di Kerjasama.co.id ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Kerjasama.co.id</strong> merupakan platform
                    investasi bisnis dengan sistem Securities Crowdfunding/SCF
                    (urun dana) yang sudah mendapatkan izin sebagai
                    Penyelenggara dari Otoritas Jasa Keuangan, untuk
                    memfasilitasi bisnis anda dengan pilihan jenis efek seperti
                    Saham, Bond (Obligasi){/* dan Sukuk (Syariah)*/}
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    {/* Di Kerjasama.co.id sendiri, ada 3 jenis efek yang ditawarkan oleh Penerbit, yaitu : */}
                    Di Kerjasama.co.id sendiri, ada 2 jenis efek yang ditawarkan
                    oleh Penerbit, yaitu :
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Saham</strong>
                    <br />
                    Saham merupakan produk investasi dengan berbagi kepemilikan
                    perusahaan, dengan sistem pembagian dividen sesuai dengan
                    porsi kepemilikan saham sesuai dengan jangka waktu yang
                    telah ditentukan, dengan nilai pendanaan maksimal Rp 10
                    Miliar/tahun tiap penerbit.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Bond (Obligasi)</strong>
                    <br />
                    Produk Bond atau Obligasi adalah surat berharga dengan
                    bentuk imbal hasil berjangka (fixed return). Obligasi berisi
                    janji dari pihak yang menerbitkan efek untuk membayar imbal
                    hasil (kupon) pada periode tertentu dan melunasi pokok pada
                    saat jatuh tempo. Produk Obligasi merupakan fasilitas
                    pendanaan yang bertujuan untuk membiayai sebuah proyek
                    maupun operasi bisnis sebuah perusahaan untuk kurun waktu
                    tertentu dengan jaminan properti/kontrak/PO/invoice sebagai
                    landasan proyek, dengan nilai pendanaan maksimal Rp 10
                    Miliar/tahun tiap penerbit.
                  </p>
                  {/* <p style={{marginLeft:25}}>
                                    <strong>Sukuk (Syariah)</strong>
                                    <br/>Obligasi Syariah merupakan alat investasi atau transaksi dengan menerapkan sistem pembiayaan dan pendanaannya sesuai dengan hukum syariat Islam yang berlaku. Surat - surat berharga jangka panjang yang berprinsip Syariah dan dikeluarkan oleh emiten (penerbit) kepada pemegang surat obligasi berbentuk bagi hasil dan pembayaran kembali dana tersebut sesuai dengan jatuh tempo, dengan nilai pendanaan maksimal Rp 10 Miliar/tahun tiap penerbit.
                                </p> */}
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  4. Apakah Kerjasama.co.id sudah memiliki izin dari OJK sebagai
                  penyelenggara Securities Crowdfunding ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <p style={{ marginLeft: 25 }}>
                    Ya, Kerjasama.co.id sudah mendapatkan izin resmi sebagai
                    Penyelenggara Securities Crowdfunding dari Otoritas Jasa
                    Keuangan (OJK), berdasarkan Surat Keputusan Anggota Dewan
                    Komisioner OJK Nomor : KEP ….. tertanggal ….. , sesuai POJK
                    57 tahun 2020.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  5. Bagaimana cara menjadi Investor ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <p style={{ marginLeft: 25 }}>
                    1. Sign Up dan Login sebagai Investor
                    <br />
                    2. Melengkapi profil investor dan proses KYC
                    <br />
                    <List bulleted style={{ marginLeft: 30 }}>
                      <List.Item>
                        Isi profil user termasuk foto selfie, foto KTP
                      </List.Item>
                      <List.Item>
                        Menunggu verifikasi admin max 1 x 24 Jam
                      </List.Item>
                    </List>
                    3. Melihat kampanye penggalangan dana yang sedang
                    berlangsung
                    <br />
                    4. Cek detail bisnis, latar belakang, prospektus dan data
                    historikal perusahaan
                    <br />
                    5. Pembayaran transaksi investasi melalui Escrow Account
                    <br />
                    6. Mendapatkan email konfirmasi pembelian efek
                    <br />
                    7. Cek performa bisnis dan/atau laporan keuangan di
                    dashboard investor
                    <br />
                    8. Tarik keuntungan investasi anda dari akun investor ke
                    rekening bank yang terdaftar
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  6. Berapa minimal berinvestasi di Kerjasama.co.id ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <p style={{ marginLeft: 25 }}>
                    Investasi mulai dari Rp. 50.000 / lembar atau unit efek,
                    dengan minimal pembelian efek yang bisa menyesuaikan secara
                    otomatis.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 6}
                  index={6}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  7. Apa risiko berinvestasi di Kerjasama.co.id ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Risiko Usaha</strong>
                    <br />
                    Risiko Usaha adalah sesuatu yang tidak dapat dihindari dalam
                    suatu usaha/bisnis. Beberapa risiko yang dapat terjadi
                    seperti penutupan bisnis secara sementara akibat kebijakan
                    Pemberlakukan Pembatasan Kegiatan Masyarakat (PPKM) oleh
                    Pemerintah, maupun secara permanen akibat bisnis yang
                    terdampak pandemi Covid 19.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Cara Kerjasama meminimalisir risiko?</strong>
                    <br />
                    Kerjasama akan bekerjasama dengan Penerbit terbaik dan
                    memilih bisnis yang memiliki riwayat yang baik selama
                    bertahun-tahun.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Kerugian Investasi</strong>
                    <br />
                    Setiap investasi memiliki tingkat risiko yang bervariasi
                    seperti tidak terkumpulnya dana investasi yang dibutuhkan
                    selama proses pengumpulan dana atau proyek yang dijalankan
                    tidak menghasilkan keuntungan sesuai yang diharapkan.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Cara Kerjasama meminimalisir risiko?</strong>
                    <br />
                    Kerjasama menjamin bahwa semua proyek yang berjalan dan
                    Pemodal yang berpastisipasi dalam proyek tersebut akan
                    mendapatkan keuntungan sesuai di detail proyek. Kerjasama
                    akan mereviu Penerbit dengan teliti sesuai standar yangtelah
                    ditetapkan sehingga proyek yang ada di Kerjasama akan
                    terjamin. Selain itu,Pemodal sebaiknya menginvestasikan
                    modalnya di beberapa proyek untuk meminimalisir risiko.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Kekurangan Likuiditas</strong>
                    <br />
                    Investasi Anda mungkin saja tidak likuid karena efek
                    bersifat ekuitas yang ditawarkan tidak terdaftar di bursa
                    efek. Ini berarti bahwa Anda mungkin tidak dapat dengan
                    mudah menjual saham Anda di bisnis tertentu atau Anda
                    mungkin tidak dapat menemukan pembeli sebelum berakhirnya
                    jangka waktu investasi di pasar sekunder.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Cara Kerjasama meminimalisir risiko?</strong>
                    <br />
                    Kerjasama akan secara aktif memasarkan saham Anda dan
                    melakukan yang terbaik untuk memfasilitasi penjualan dan
                    pembelian saham Anda. Kami akan menyediakan harga referensi
                    saham untuk memudahkan Anda dalam bertransaksi. Namun, Kami
                    tidak menjamin akan menemukan pembeli untuk Anda. Saham
                    Penerbit dapat dialihkan setelah 1 (satu) tahun saham
                    diterbitkan dan pasar sekunder (perdagangan saham Penerbit)
                    akan dilaksanakan dalam periode tertentu sebanyak maksimum
                    2(dua) kali dalam 1 (satu) tahun.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Kegagalan Sistem Elektronik</strong>
                    <br />
                    Sistem di Kerjasama sudah menerapkan sistem elektronik dan
                    keamanan data yang handal. Namun, tetap dimungkinkan terjadi
                    Gangguan sistem teknologi informasi dan kegagalan sistem,
                    serta alih kelola sistem Teknologi Informasi yang
                    menyebabkan aktivitas Anda di platform Kerjasama menjadi
                    tertunda.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Cara Kerjasama meminimalisir risiko?</strong>
                    <br />
                    Kerjasama akan menggunakan multi server untuk meminimalisir
                    risiko kegagalan sistem elektronik. Multi server berfungsi
                    sebagai antar muka antara Pengguna dengan database/sistem.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Gagal Bayar atas Efek Bersifat Utang</strong>
                    <br />
                    Penerbit dapat menerbitkan Efek bersifat Utang melalui
                    Platform Kerjasama. Investasi ini juga memiliki risiko yaitu
                    Penerbit mengalami kondisi gagal bayar/default sehingga
                    tidak bisa membayar utang pokok atau bunga
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Cara Kerjasama meminimalisir risiko?</strong>
                    <br />
                    Kerjasama akan melakukan tindakan sebagai berikut:
                    <br />
                    <List bulleted style={{ marginLeft: 30 }}>
                      <List.Item>
                        Melakukan prosedur pengembalian asset
                      </List.Item>
                      <List.Item>
                        Eksekusi hak tanggungan (Akta Pemberian Hak Tanggungan
                        atau “APHT’) kepengadilan sesuai dengan ketentuan
                        peraturan perundang-undangan yang berlaku:
                        <br />
                        <List bulleted style={{ marginLeft: 30 }}>
                          <List.Item>
                            Hak tanggungan berupa jaminan dalam bentuk tanah
                            atau bangunan atau kapal ukuran besar.
                          </List.Item>
                          <List.Item>
                            Hak tanggungan harus didaftarkan terlebih dahulu
                            oleh notaris
                          </List.Item>
                        </List>
                      </List.Item>
                    </List>
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Keamanan Data</strong>
                    <br />
                    Anda bertanggung jawab dengan data pribadi Anda
                    masing-masing seperti emaildan kata sandi. Pihak Kerjasama
                    tidak bertanggung jawab jika terjadi kecuranganyang
                    dilakukan pihak ketiga. Anda bisa melaporkan ke Kerjasama
                    jika terjadi hal mencurigakan dengan akun Anda ke Layanan
                    Pengguna Kami di: cs@kerjasama.co.id.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 7}
                  index={7}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  8. Apa tanggung jawab saya sebagai investor ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 7}>
                  <p style={{ marginLeft: 25 }}>
                    Segala bentuk kewajiban perpajakan yang timbul atas
                    transaksi investasi pada platform Kerjasama.co.id wajib
                    disetor sendiri oleh Wajib Pajak orang pribadi dalam negeri
                    dengan tarif sesuai dalam ketentuan perundang undangan
                    sebagaimana tertulis dalam Peraturan Menteri Keuangan
                    Republik Indonesia Nomor 18/PMK.03/2021 tentang pelaksanaan
                    Undang Undang nomor 11 tahun 2020 , tentang cipta kerja di
                    bidang pajak penghasilan, pajak pertambahan nilai, dan pajak
                    penjualan atas barang mewah, serta ketentuan umum dan tata
                    cara perpajakan.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    Membayarkan biaya layanan untuk efek bersifat ekuitas
                    (Saham) sebesar 10% atau 5% dan management fee sebesar 5%.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    Menjaga nama baik platform Kerjasama.co.id,
                    penerbit/pengelola bisnis/franchisor dan sesama investor
                    dalam hal tutur kata yang sopan tidak provokatif, bijak
                    serta menjaga kondusifitas secara langsung, maupun tidak
                    langsung, seperti di media sosial (whatsapp, telegram,
                    twitter, instagram, facebook dain lainnya) termasuk tidak
                    berdiskusi politik, SARA, dsb.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    Investor berkomitmen untuk tidak turun langsung dalam
                    operasional bisnis yang menjadi ranah pengelola
                    bisnis/penerbit, dalam kapasitasnya sebagai investor dan
                    hanya menggunakan haknya melalui jalur yang sesuai ketentuan
                    perundang-undangan yang berlaku yaitu RUPS/RUPO.
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    Investor berkewajiban untuk menjaga kerahasiaan setiap
                    informasi, data dan dokumen, yang diberikan dan atau
                    didapatkan dari Kerjasama.co.id maupun dari
                    penerbit/pengelola bisnis dalam bentuk lisan, tertulis,
                    grafik, atau file elektronik dan setiap bentuk salinan
                    terkait pelaksanaan operasional bisnis di Kerjasama.co.id.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 8}
                  index={8}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  9. Biaya apa saja yang harus saya keluarkan ?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 8}>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Biaya Pemodal</strong>
                    <br />
                    1. Management Fee sebesar 5% dari setiap keuntungan bisnis/
                    bagi hasil. Kami selalu berusaha memberikan pelayanan
                    terbaik, membantu melakukan distribusi laporan keuangan,
                    operasional dan pembagian keuntungan bisnis secara otomatis
                    di akun Saldo Efek anda. Contoh 1, untuk produk investasi
                    saham: Jika sebuah bisnis menghasilkan keuntungan Rp
                    30.000.000 per bulan. Jika persentase kepemilikan bisnis
                    anda sebesar 1%, berarti keuntungan yang anda terima adalah
                    sebesar Rp 300.000, maka biaya yang dibayarkan untuk
                    Kerjasama.co.id hanya sebesar Rp 15.000 saja. Contoh 2,
                    untuk produk investasi Obligasi: Jika anda mendapatkan bagi
                    hasil/kupon sebesar Rp 30.000.000 per bulan dan persentase
                    kepemilikan efek anda sebesar 1%, berarti keuntungan yang
                    anda terima adalah sebesar Rp 300.000.
                    <br />
                    2. Untuk setiap penerimaan dana atas hasil penjualan
                    investasi dalam pasar sekunder, akan dikenakan fee layanan
                    (platform fee) sebesar 2,5 % (dua koma lima persen) dari
                    keseluruhan nilai penerimaan yang diterima oleh pemodal yang
                    melakukan penjualan investasi. Biaya fee layanan tersebut
                    akan dikenakan secara otomatis sebagai biaya menyediakan,
                    mengelola, dan mengoperasikan jaringan sistem elektronik
                    Layanan Urun Dana sesuai Peraturan OJK tentang Layanan Urun
                    Dana.
                    <br />
                    3. Biaya Transaksi per 1 kali transfer dikenakan Rp. 6.500
                    per transaksi. <br />
                  </p>
                  <p style={{ marginLeft: 25 }}>
                    <strong>Biaya Penerbit</strong>
                    <br />
                    1. PENERBIT berkewajiban melakukan deposit sebesar 5% dari
                    total nilai penyelenggaraan layanan urun dana dan
                    menggunakan deposit tersebut sebagai nilai pembayaran komisi
                    ke PENYELENGGARA atas layanan urun dana yang berhasil
                    terhimpun. Selain itu PENERBIT juga membayar biaya-biaya
                    kepada PENYELENGGARA dan/atau pihak terkait atas
                    penyelenggaraan Layanan Urunan Dana yang dilaksanakan
                    PENYELENGGARA bagi kepentingan PENERBIT.
                    <br />
                    2. PENERBIT berkewajiban membayar (melalui PENYELENGGARA)
                    Biaya dan Layanan atas jasa Lembaga Penyimpanan dan
                    Penyelesaian yang terdiri dari:
                    <br />
                    &nbsp; &nbsp; a) Joining Fee KSEI yang dibayarkan sekali di
                    awal sebesar Rp. 3.750.000
                    <br />
                    &nbsp; &nbsp; b) Annual Fee KSEI sebesar Rp. 2.500.000
                    <br />
                    &nbsp; &nbsp; c) Biaya Tahunan Kustodian sebesar 0.05%
                    (terhitung dari total dana yang terhimpun)
                    <br />
                    &nbsp; &nbsp; d) Fee atas jasa agen pembayaran (apabila
                    diperlukan).
                    <br />
                    3. PENERBIT berkewajiban membayarkan biaya Administrasi
                    (termasuk survey) Rp. 10.000.000 untuk penerbit yang
                    berlokasi di wilayah Jawa. Biaya ini dibayarkan sebelum
                    dilakukan penelaahan penerbit. Biaya ini bersifat tentative
                    untuk penerbit yang berlokasi di wilayah luar Jawa.
                    <br />
                    4. Para Pihak sepakat bahwa segala pajak yang timbul
                    sehubungan dengan pelaksanaan perjanjian ini akan ditanggung
                    oleh masing-masing Pihak berdasarkan ketentuan dan peraturan
                    perpajakan yang berlaku. <br />
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 9}
                  index={9}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  10. Bagaimana SOP Pelayanan yang saya dapatkan dari
                  Kerjasama.co.id
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 9}>
                  <p style={{ marginLeft: 25 }}>
                    Investor dapat langsung menghubungi kami melalui Live Chat
                    yang ada di halaman Kerjasama.co.id selama jam kerja 08:00 -
                    16:00 WIB (Senin - Jumat) dan 08:00 - 12:00 WIB (Sabtu) atau
                    melalui email di cs@kerjasama.co.id. Kami akan membantu
                    keluhan anda maksimal 1x24 Jam keluhan di terima.
                  </p>
                </Accordion.Content>
              </Accordion>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}
