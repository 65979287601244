import React, { useState } from 'react';
import Navbar from "../../components/navbar_home";
import Footer from "../../components/Footer";
import "../../App.scss";

class TermsCondition extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.isLoading = true;
    }

    clearConsole() {
        if (window.console || window.console.firebug) {
            console.clear();
        }
    }

    render() {
        this.clearConsole();
        return (
            <div>
                <Navbar />
                <Body />
                <Footer />
            </div>
        );
    }
}

function Body() {
    return (
        <div className="container " style={{ marginTop: "50px", marginBottom: "50px" }}>
            <div className="row" >
                <div className="col-md-12 col-lg-12">
                    {/* <div className="gambar text-center">
                        <img src={Kerja} style={{ width: "30%" }} alt="" />
                    </div> */}
                    <div className="visi-misi text-center">
                        <h4 style={{ fontSize: "24px", fontWeight: "700", color: "#033C85", textTransform: "uppercase" }}>SYARAT, KETENTUAN UMUM DAN COOKIES</h4>
                    </div>
                    <div className="content" style={{ marginTop: "50px", marginLeft: "50px", marginRight: "50px" }}>
                        {/* <p style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 20 }}>SYARAT, KETENTUAN UMUM DAN COOKIES</p> */}

                        <p><b>1. Kata Pengantar</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Syarat dan Ketentuan Umum ini mengatur hak dan kewajiban yang mengikat secara hukum terhadap Pengguna untuk mengakses, menggunakan dan mengunjungi setiap dan seluruh laman situs (<i>website</i>) dan layanan yang terdapat pada situs https://kerjasama.co.id. Situs https://kerjasama.co.id merupakan situs milik dari PT Kerjasama Untuk Negeri (atau dikenal dengan nama “Kerjasama.co.id” atau “Penyelenggara”) yang merupakan penyelenggara penawaran efek melalui layanan urun dana berbasis teknologi informasi (<i>Securities Crowdfunding</i>) berdasarkan Peraturan Otoritas Jasa Keuangan No.57 Tahun 2020 (selanjutnya disebut <b>“POJK”</b>) yang telah memperoleh izin dari Otoritas Jasa Keuangan Republik Indonesia (selanjutnya disebut OJK RI) berdasarkan Surat Keputusan No: KEP-38/D.04/2021 tentang Pemberian Izin Usaha Penyelenggara Penawaran Efek Melalui Layanan Urun Dana Berbasis Teknologi Informasi (<i>Securities Crowdfunding</i>) PT Kerjasama Untuk Negeri.</p>
                            <p>Situs https://kerjasama.co.id merupakan Penawaran Efek melalui Layanan Urun Dana Berbasis Teknologi Informasi yang selanjutnya disebut Layanan Urun Dana adalah penyelenggaraan layanan penawaran efek yang dilakukan oleh penerbit untuk menjual efek secara langsung kepada pemodal melalui jaringan sistem elektronik yang bersifat terbuka, yang mempertemukan Pemodal dan Penerbit yang menawarkan efeknya melalui https://kerjasama.co.id (Pemodal dan Penerbit selanjutnya disebut <b>“Pengguna”</b>) Dengan mengakses dan memiliki akun pada Situs https://kerjasama.co.id, anda selaku pengguna dengan ini menyatakan menerima Syarat dan Ketentuan umum di bawah ini secara keseluruhan.</p>
                        </div>

                        <p><b>2. Kerjasama.co.id</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Kerjasama.co.id membantu para Penerbit <i>(Issuer)</i> untuk mendapatkan pendanaan dengan mengakomodir kebutuhan Usaha Kecil Menengah selanjutnya disebut (UKM) dalam memanfaatkan layanan urun dana sebagai salah satu sumber pendanaan di Pasar Modal, yaitu dengan instrumen Efek yang dapat ditawarkan melalui layanan urun dana tidak hanya berbentuk saham (Efek bersifat ekuitas) tetapi juga dapat berupa Efek bersifat utang{/* atau Sukuk*/}. Sehingga pendanaan usaha/proyek dalam rangka peningkatan modal kerja usaha, perluasan usaha (<i>business expansion</i>) dengan lebih cepat dan efisien, dengan cara menawarkan efek bersifat ekuitas, efek bersifat utang{/* atau Sukuk*/}, kepada Pemodal, tanpa harus melakukan Penawaran Umum Perdana atau <i>Initial Public Offering</i> (IPO) di Bursa Efek Indonesia atau pinjaman ke perbankan.</p>
                            <p>Lebih lanjut, Pemodal dapat berinvestasi pada Penerbit yang sudah berjalan dengan mengakuisisi sebagian saham Penerbit (pengambilalihan/<i>take over</i>) dan/atau melakukan membuka usaha/proyek baru peningkatan modal /atau <i>Right Issue</i> (“<i>grand opening</i>“). Pemodal dapat juga berinvestasi pada Efek bersifat Utang (Obligasi) dimana proyek-proyek yang akan didanai akan jatuh tempo paling lama 2 (Dua) tahun dengan mendapatkan keuntungan <i>Fixed Coupon</i> atau Kupon tetap. Jika yang ditawarkan Efek bersifat utang, maka pemodal akan mendapatkan bagi hasil yang perhitungannya berdasarkan prinsip syariah dengan mendapatkan bagi hasil secara berkala dalam periode tertentu dengan modal yang tidak terlalu besar.</p>
                            <p>Kerjasama.co.id akan menyajikan informasi mengenai usaha/proyek yang akan didanai bersama, legalitas penerbit/proyek, lokasi usaha/proyek, sejarah berdirinya penerbit, kondisi usaha/proyek, termasuk data lampau atas perkembangan usaha/proyek serta data laporan keuangan dari minimal 3 (tiga) usaha/proyek sejenis yang sudah berjalan sebagai bahan rujukan dan pertimbangan Pemodal sebelum berinvestasi melalui situs https://kerjasama.co.id.</p>
                            <p>Yang dimaksud dengan Efek adalah surat berharga, yaitu surat pengakuan utang, surat berharga komersial, saham, obligasi, tanda bukti utang, unit penyertaan kontrak investasi kolektif, kontrak berjangka atas Efek, dan setiap derivatif dari Efek.</p>
                        </div>

                        <p><b>3. Pendanaan Usaha/Proyek</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Proyek adalah kegiatan atau pekerjaan yang menghasilkan barang, jasa, dan/atau manfaat lain, baik yang sudah ada maupun yang akan ada, termasuk kegiatan investasi yang telah ditentukan yang akan menjadi dasar penerbitan atas Efek bersifat utang{/* atau Sukuk*/}. Penerbit dapat mengajukan permohonan pendanaan usaha/proyek, baik yang sudah berjalan maupun usaha baru yang akan dibentuk (untuk selanjutnya disebut <b>“Pendanaan Usaha/Proyek”</b>) pada situs https://kerjasama.co.id. Untuk dapat melakukan penawaran pendanaan usaha/proyek (<b>“Penawaran Efek”</b>), Penerbit dapat mengajukan pendaftaran atas usaha/proyek yang akan didanai melalui layanan urun dana serta berhak untuk menggunakan layanan pada situs https://kerjasama.co.id, yang memuat data dan informasi atas usaha/proyek yang akan didanai bersama dengan cara penawaran efek melalui layanan urun dana berbasis teknologi informasi (securities crowdfunding). Usaha/proyek yang akan didanai melalui layanan urun dana harus berbadan hukum baik perseroan terbatas (PT), maupun berbadan usaha lainnya seperti Koperasi, Persekutuan Perdata, Firma, atau Persekutuan Komanditer (CV).</p>
                            <p>Penawaran Efek dilakukan melalui situs https://kerjasama.co.id, paling lama 45 (Empat Puluh Lima) hari untuk selanjutnya disebut (<b>“Masa Penawaran”</b>) setelah Penyelenggara memuat usaha/proyek Penerbit yang akan didanai melalui layanan urun dana. Penghimpunan dana oleh Penerbit melalui layanan urun dana oleh setiap Penerbit dalam jangka waktu 12 (dua belas) bulan paling banyak sebesar Rp.10.000.000.000,- (sepuluh miliar Rupiah) yang dapat dilakukan dalam 1 (satu) kali penawaran dan melalui 1 (satu) Penyelenggara dalam waktu yang bersamaan. Berdasarkan kesepakatan dengan Penyelenggara dan Penerbit dapat menetapkan minimum jumlah dana yang harus terhimpun dengan menyampaikan rencana penggunaan dan sumber pendanaan lain untuk pendanaan usaha/proyek. Selama masa penawaran, Penerbit dilarang dan/atau tidak dibenarkan untuk mengubah jumlah minimum dana yang harus terhimpun, dan membatalkan secara sepihak pendanaan usaha/proyek pada masa penawaran.</p>
                            <p>Apabila jumlah minimum dana yang harus terhimpun tidak terpenuhi, maka Penawaran Efek melalui layanan urun dana tersebut menjadi batal demi hukum (<i>null and void</i>). Untuk itu, penyelenggara akan mengembalikan dana beserta seluruh manfaat yang timbul dari dana tersebut selama dalam rekening penampung (<i>escrow account</i>) secara proporsional kepada pemodal selambat-lambatnya 2 (dua) hari kerja setelah penawaran Efek batal demi hukum.</p>
                            <p>Pihak yang memiliki kemampuan analisis risiko terhadap Efek Penerbit dan memenuhi kriteria Pemodal sebagaimana diatur dalam POJK 57 tahun 2020 dan berkeinginan untuk melakukan penyertaan efek dan/atau pembelian Efek (selanjutnya disebut <b>“Pemodal”</b>) dapat membeli Efek melalui layanan urun dana dengan membuat akun dan mengisi data-data yang diperlukan dan melakukan penyetoran Efek yang akan dibeli ke dalam rekening penampung (<i>escrow account</i>) yang dikelola oleh penyelenggara serta memberi kuasa kepada penyelenggara untuk mewakili pemodal sebagai pemegang Efek Penerbit termasuk untuk menghadiri kuasa Rapat Umum Pemegang Saham (RUPS) dan atau Rapat Umum Pemegang Obligasi (RUPO) Penerbit dan sebagai kuasa Pemodal dalam melakukan penandatanganan dokumen terkait dan lain sebagainya.</p>
                            <p>Pemodal dapat melakukan pembatalan atas pembelian Efek milik Penerbit melalui layanan urun dana paling lambat 2x24 jam setelah melakukan pembelian Efek dan paling lambat 2 (dua) hari kerja setelah pembatalan pemesanan pembelian Efek, Penyelenggara akan mengembalikan dana milik pemodal ke dalam rekening yang ditunjuk oleh Pemodal.</p>
                            <p>Setelah dana yang diperlukan untuk pendanaan usaha/proyek telah mencukupi, maka Penyelenggara akan menyatakan bahwa pendanaan usaha/proyek tersebut telah “Terpenuhi“ atau “<i>Sold Out</i>“. Selanjutnya penyelenggara akan menyerahkan dana dari pemodal kepada penerbit selambat-lambatnya 2 (dua) hari kerja setelah fotokopi perjanjian pendaftaran efek diterima penyelenggara (apabila efek yang ditawarkan bersifat ekuitas berupa saham) atau 2 (dua) hari kerja setelah fotokopi perjanjian pendaftaran ek dan dokumen dasar pengerjaan atau bukti kepemilikan proyek (apabila Efek yang ditawarkan bersifat utang{/* atau Sukuk*/}). alam hal Penerbit dinyatakan berhasil melakukan Pendanaan melalui Layanan Urun Dana penerbit diwajibkan menyerahkan efek kepada penyelenggara untuk didistribusikan kepada Pemodal paling lambat 2 (dua) hari kerja setelah masa penawaran Efek berakhir dan pendistribusian saham dapat dilakukan secara elektronik melalui penitipan kolektif pada kustodian paling lambat 10 (sepuluh) hari kerja.</p>
                            <p>Dalam hal Efek yang ditawarkan bersifat ekuitas berupa saham, Penerbit wajib menandatangani perjanjian pendaftaran Efek dengan Lembaga Penyimpanan dan Penyelesaian (LPP), dan menyampaikan fotokopinya kepada Penyelenggara selambat-lambatnya 10 (sepuluh) hari kerja setelah Penerbit melakukan penyetoran Efek kepada Penyelenggara. Apabila dalam jangka waktu tersebut, Penerbit tidak menyerahkan fotokopi perjanjian pendaftaran Efek, maka Penawaran Efek yang dilakukan penerbit batal demi hukum, dan dengan demikian maka Penyelenggara akan mengembalikan dana beserta seluruh manfaat yang timbul dari dana tersebut selama dalam <i>escrow account</i> secara proporsional kepada Pemodal paling lambat 2 (dua) hari kerja setelah Penawaran Efek batal demi hukum.</p>
                            <p>Dalam hal Efek yang ditawarkan bersifat utang{/* atau Sukuk*/}, Penerbit wajib menandatangani perjanjian pendaftaran Efek dengan Lembaga Penyimpanan dan Penyelesaian, serta membuat akta pengakuan hutang yang dibuat secara notariil oleh notaris, dan menyampaikan fotokopi kedua dokumen tersebut kepada Penyelenggara selambat-lambatnya 10 (sepuluh) hari kerja setelah Penerbit melakukan penyetoran Efek kepada Penyelenggara. Apabila dalam jangka waktu tersebut, penerbit tidak menyerahkan dokumen-dokumen sebagaimana yang dimaksud beserta dokumen dasar pengerjaan atau bukti kepemilikan Proyek, maka Penawaran Efek yang dilakukan Penerbit batal demi hukum, dan dengan demikian maka Penyelenggara akan mengembalikan dana beserta seluruh manfaat yang timbul dari dana tersebut selama dalam <i>escrow account</i> secara proporsional kepada Pemodal paling lambat 2 (dua) hari kerja setelah penawaran Efek batal demi hukum.</p>
                            <p>Apabila dalam pelaksanaan proses transaksi melalui Platform https://kerjasama.co.id ditemukan pelanggaran termasuk namun tidak terbatas pada ketentuan peraturan perundang-undangan, peraturan otoritas jasa keuangan tentang layanan urun dana serta syarat dan ketentuan umum ini, maka Penyelenggara berhak membatalkan transaksi sebagaimana dimaksud.</p>
                        </div>

                        <p><b>4. Bagi Hasil</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Laba bersih dari hasil usaha Penerbit pada efek bersifat ekuitas (selanjutnya akan disebut <b>“Dividen”</b>) dan bagi hasil dari proyek penerbit pada efek bersifat utang{/* atau Sukuk*/} (selanjutnya disebut bunga <b>“Kupon”</b>) nisbah bagi hasil, margin, imbal jasa atau imbal hasil atau imbalan dengan cara lain akan dibagikan kepada pemegang efek sesuai dengan jumlah kepemilikan efeknya pada perusahaan Penerbit. Pembagian laba bersih kepada Pemodal dilakukan pada setiap akhir tahun buku berdasarkan rekomendasi dari Direksi Perseroan dengan persetujuan dari rapat umum pemegang saham (Rups) Penerbit.</p>
                            <p>Terlepas dari yang sudah ditentukan di atas, berdasarkan rekomendasi dari Direksi Perseroan dengan persetujuan dari rapat umum pemegang saham Penerbit, Penerbit dapat melakukan pembagian dividen pada masa tahun buku perseroan (selanjutnya disebut <b>“Dividen Interim”</b>) yang akan dilakukan oleh Penyelenggara melalui Saldo Efek. Pendistribusian Dividen Interim melalui Saldo Efek dilakukan Pemodal dengan menyampaikan instruksi pencairan Saldo Efek dengan mencantumkan jumlah nominal yang akan dilakukan pencairan.</p>
                        </div>

                        <p><b>5. Saldo Efek</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Setiap dana yang masuk ke dalam Saldo Efek dapat ditarik sewaktu-waktu oleh Pemodal dengan cara menyampaikan instruksi pencairan dana melalui <i>platform</i> yang disediakan oleh kerjasama.co.id, Penyelenggara akan melakukan pemindahbukuan Saldo Efek ke dalam rekening yang ditunjuk oleh Pemodal selambat-lambatnya 3 (tiga) hari kerja.</p>
                        </div>

                        <p><b>6. Pernyataan dan Jaminan</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: "baseline" }}>A. </td>
                                        <td colSpan="2">Penyelenggara dengan ini menyatakan dan menjamin hal-hal sebagai berikut:</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>1. </td>
                                        <td>Penyelenggara adalah badan hukum yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan Negara Kesatuan Republik Indonesia.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>2. </td>
                                        <td>Sepanjang yang diketahui oleh Penyelenggara, Penyelenggara dalam menjalankan kegiatan usahanya telah memiliki setiap perizinan yang dipersyaratkan termasuk namun tidak terbatas pada izin dalam bidang penyelenggaraan penawaran efek melalui layanan urun dana berbasis teknologi (<i>securities crowdfunding</i>) dari Otoritas Jasa Keuangan Republik Indonesia (OJK RI) dan perizinan tersebut, sampai ini masih berlaku dan/atau tidak sedang ditangguhkan oleh pihak otoritas yang berwenang.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>3. </td>
                                        <td>Sepanjang yang diketahui oleh Penyelenggara, Penyelenggara dalam menjalankan kegiatan usahanya dalam hal kepemilikan sahamnya penyelenggara oleh warga negara asing dan/atau badan hukum asing, baik secara langsung maupun tidak langsung paling banyak 49% (Empat Puluh Sembilan Persen).</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>4. </td>
                                        <td>Sepanjang yang diketahui oleh Penyelenggara, penyelenggara dalam menjalankan kegiatan usahanya tidak melanggar hak atas kekayaan intelektual dari pihak ketiga lainnya.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>5. </td>
                                        <td>Penyelenggara dengan ini menyatakan bahwa dalam memberikan dan/atau menyajikan data-data dan informasi-informasi terkait dengan penerbit dan/atau penawaran Efek melalui layanan urun dana yang diselenggarakan oleh Penyelenggara, Penyelenggara sepenuhnya mempercayakan, mengandalkan dan bersandarkan pada semua data-data dan/atau informasi-informasi yang diberikan oleh Penerbit kepada Penyelenggara dengan terlebih dahulu <i>due diligence</i> baik secara legal maupun secara finansial.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>6. </td>
                                        <td>Penyelenggara dengan ini menyatakan bahwa Penyelenggara tidak pernah membuat pernyataan-pernyataan atau jaminan-jaminan, baik secara tegas maupun tersirat, terhadap kualitas, akurasi dan kelengkapan informasi yang disediakan oleh Penyelenggara pada layanan urun dana melalui penawaran efek berbasis teknologi terkait dengan kegiatan usaha Penerbit.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>7. </td>
                                        <td>Penyelenggara dengan ini menyatakan dan menjamin bahwa dana pemodal yang ada dalam rekening <i>virtual account</i> dan/atau <i>escrow account</i> atas nama Penyelenggara dan/atau atas nama bersama dari Penyelenggara, Penerbit dan Pemodal adalah merupakan dana milik masing-masing Pemodal, dan bukan merupakan harta kekayaan (aset) milik Penyelenggara. Selanjutnya, harta (aset) tersebut tidak dapat dan bukan merupakan harta pailit (<i>boedel pailit</i>), dalam hal Penyelenggara dinyatakan pailit berdasarkan peraturan perundang-undangan yang berlaku.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "baseline" }}>B. </td>
                                        <td colSpan="2">Pemodal dengan ini menyatakan dan menjamin hal-hal sebagai berikut:</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>1. </td>
                                        <td>Bahwa Pemodal adalah (a) badan hukum yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan Negara Kesatuan Republik Indonesia (dalam hal Pemodal merupakan badan hukum, dan (b) subjek hukum yang cukup umur, sehat akal pikiran dan berhak serta berwenang untuk mengikatkan diri (dalam hal Pemodal merupakan perorangan) pada syarat dan ketentuan umum penggunaan layanan ini.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>2. </td>
                                        <td>Pemodal yang dapat membeli Efek melalui layanan urun dana wajib memiliki Rekening Efek dan/atau dana melalui layanan urun dana, memiliki kemampuan untuk membeli Efek Penerbit dan memenuhi kriteria Pemodal dan batasan pembelian Efek.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>3. </td>
                                        <td>Pemodal yang dapat membeli Efek melalui layanan urun dana:<br />
                                            a. Memiliki penghasilan sampai dengan Rp 500.000.000 (Lima Ratus Juta Rupiah) pertahun, maksimum investasi 5% (Lima Persen) dari penghasilan per tahun; b. Memiliki penghasilan lebih dari Rp 500.000.000 (Lima Ratus Juta), maksimum investasi 10% (Sepuluh Persen) dari penghasilan per tahun; dan c. Namun, apabila: 1) Pemodal merupakan badan hukum; 2) Pemodal memiliki pengalaman investasi di pasar modal; atau 3) Efek bersifat utang{/* atau Sukuk*/} dijamin atau ditanggung dengan nilai penjaminan atau nilai penanggungan paling sedikit 125% (Seratus Dua Puluh Lima Persen) dari nilai penghimpunan dana, maka jumlah investasi pemodal tidak dibatasi.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>4. </td>
                                        <td>Pemodal dengan ini menyatakan dan menjamin bahwa setiap data dan informasikan yang diberikan oleh Pemodal pada saat pembukaan akun untuk menggunakan penawaran efek melalui layanan urun dana berbasis teknologi yang disediakan Penyelenggara, adalah benar, lengkap dan sesuai dengan dokumen aslinya, otentik, dan akurat serta masih berlaku. Selanjutnya, Pemodal juga menyatakan bahwa tanda tangan yang diberikan dan/atau dibubuhkan baik secara fisik maupun elektronik adalah benar tanda tangan Pemodal dan bukan merupakan tanda tangan pihak lain.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>5. </td>
                                        <td>Pemodal dengan ini menyatakan dan menjamin bahwa Pemodal telah membaca, memeriksa, menganalisa dan mengevaluasi prospektus, data, informasi maupun kelengkapan dokumen dari usaha/proyek Penerbit yang dimuat melalui penawaran efek melalui layanan urun dana berbasis teknologi informasi yang disediakan Penyelenggara, Pemodal dengan ini menyatakan dan menjamin bahwa Pemodal telah memilih kegiatan usaha/proyek dari Penerbit secara sadar, tanpa ada bujuk rayu, paksaan, ancaman dan pengaruh dari pihak manapun termasuk dari Penyelenggara.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>6. </td>
                                        <td>Pemodal dengan ini menyatakan dan menjamin bahwa Pemodal dalam melakukan penyertaan efek (pembelian efek) pada Penerbit telah membaca, memeriksa, menganalisa dan mengevaluasi prospektus/mini prospektus, data, informasi maupun kelengkapan dokumen dari usaha/proyek Penerbit pada layanan urun dana yang disediakan oleh Penyelenggara dan dalam melakukan penyertaan modal pada Penerbit, Pemodal menyatakan dan mengakui risiko-risiko terkait dengan kualitas, akurasi dan kelengkapan informasi terkait dengan kegiatan usaha Penerbit yang disajikan dan/atau diinformasikan melalui layanan urun dana yang disediakan oleh Penyelenggara. Selanjutnya, Pemodal menyatakan dan mengetahui bahwa setiap data dan prospektus, keuntungan usaha/proyek maupun risiko kegiatan operasional Penerbit yang disajikan dan/atau diinformasikan oleh Penyelenggara penawaran efek melalui layanan urun dana berbasis teknologi, merupakan data dan prospektus yang disajikan oleh Penyelenggara berdasarkan informasi yang diberikan dan/atau disediakan oleh Penerbit kepada Penyelenggara dengan berdasarkan pada data dan/atau informasi serta kejadian yang telah lalu dan mengandung unsur ketidakpastian tergantung dari potensi kegiatan usaha/proyek Penerbit, kondisi umum dan khusus dari kegiatan operasional Penerbit. Dan karenanya, Pemodal dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan risiko terkait dengan kualitas, akurasi dan kelengkapan informasi yang mungkin akan timbul dan atau mungkin akan terjadi terhadap setiap data dan prospektus kegiatan operasional, keuntungan usaha/proyek maupun risiko kegiatan operasional Penerbit yang disajikan dan/atau diinformasikan oleh Penyelenggara melalui layanan urun dana melalui penawaran efek berbasis teknologi.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>7. </td>
                                        <td>Pemodal dengan ini menyatakan, bahwa Pemodal sepenuhnya mengetahui, memahami dan menyadari resiko yang terkandung dan resiko yang mungkin akan timbul dikemudian hari atas kegiatan usaha Penerbit yang ditawarkan melalui penyelenggaraan layanan urun dana melalui penawaran efek berbasis teknologi (<i>securities crowdfunding</i>), termasuk namun tidak terbatas pada penurunan performa usaha dan/atau usaha Penerbit tidak menghasilkan keuntungan dan/atau profit, risiko gagal bayar pada efek bersifat utang, hingga Penerbit dinyatakan bangkrut maupun pailit. Dan karenanya, Pemodal dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan risiko yang mungkin akan timbul dan atau mungkin akan terjadi atas kegiatan usaha Penerbit, termasuk namun tidak terbatas pada kegagalan kegiatan usaha/proyek Penerbit untuk memperoleh penghasilan dan/atau profit maupun Penerbit dinyatakan bangkrut maupun pailit.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>8. </td>
                                        <td>Pemodal dengan ini menyatakan bahwa Pemodal sepenuhnya mengetahui, memahami dan menyadari resiko yang terkandung dan resiko yang mungkin akan timbul dikemudian hari atas pelaksanaan perdagangan efek bersifat ekuitas atau saham Penerbit pada pasar sekunder, termasuk namun tidak terbatas tidak likuidnya saham Penerbit yang ditawarkan oleh Pemodal melalui layanan pasar sekunder yang disediakan oleh Penyelenggara melalui layanan urun dana melalui penawaran efek berbasis teknologi. Dan karenanya, Pemodal dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan risiko yang mungkin akan timbul dan/atau mungkin akan terjadi atas pelaksanaan perdagangan saham Penerbit pada layanan pasar sekunder yang disediakan oleh Penyelenggara, termasuk namun tidak terbatas tidak likuidnya saham yang ditawarkan oleh Penerbit pada pasar sekunder yang disediakan oleh Penyelenggara melalui layanan urun dana melalui penawaran saham berbasis teknologi.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>9. </td>
                                        <td>Pemodal dengan ini menyatakan dan menjamin kepada Penyelenggara bahwa dana yang disetorkan oleh Pemodal ke dalam rekening <i>virtual account</i> dan/atau <i>escrow account</i> Penyelenggara, dan yang digunakan oleh Pemodal untuk melakukan penyertaan efek (pembelian efek) pada Penerbit merupakan milik Pemodal sendiri dan diperoleh dengan cara yang halal dan tidak bertentangan dengan hukum dan Perundang-undangan yang berlaku di Negara Kesatuan Republik Indonesia, termasuk dan tidak terbatas pada tindak pidana korupsi, tindak pidana pencucian uang dan pendanaan terorisme;</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>10. </td>
                                        <td>Pemodal dengan ini menyatakan akan melindungi, menjamin, memberikan ganti kerugian dan membebaskan serta melepaskan Penyelenggara dari setiap klaim, tuntutan, gugatan, tuntutan ganti rugi, tuntutan pertanggungjawaban dan/atau tuntutan sejenis lainnya yang mungkin diajukan oleh Pemodal, Penerbit, dan/atau pihak ketiga lainnya, baik langsung maupun tidak langsung, terhadap setiap kerugian, kehilangan dan/atau kerusakan harta benda, cidera fisik maupun mental maupun kehilangan nyawa pihak ketiga lainnya, terkait dengan kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha/proyek dari Penerbit.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>11. </td>
                                        <td>Pemodal dengan ini menyatakan bahwa Pemodal sepenuhnya mengetahui, memahami dan menyadari bahwa Penyelenggara tidak melakukan kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha/proyek dari Penerbit. Pemodal selanjutnya menyerahkan sepenuhnya kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha/proyek dari Penerbit kepada pihak dan/atau pihak-pihak yang ditunjuk oleh Pemodal dan/atau para Pemodal bersama-sama dengan Penerbit dan Pemodal dengan ini menyatakan dan menjamin bahwa pemodal tidak akan melakukan intervensi kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha/proyek dari Penerbit baik langsung maupun tidak langsung. Selanjutnya, Pemodal dengan ini menyatakan dan menjamin bahwa Pemodal akan menyampaikan setiap keluh-kesah, keberatan dan/atau komplain baik langsung maupun tidak langsung kepada Penerbit, direksi, komisaris, maupun Penyelenggara dan/atau pihak-pihak yang ditunjuk oleh Pemodal dan/atau para Pemodal bersama-sama dengan Penerbit untuk melakukan kegiatan usaha/proyek dan/atau pengelolaan kegiatan usaha dari Penerbit.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>12. </td>
                                        <td>Pemodal dengan ini menyatakan bahwa Pemodal sepenuhnya mengetahui, memahami dan menyadari bahwa Penyelenggara akan membebankan biaya layanan sebesar 5% (lima persen) (selanjutnya disebut <b>“Platform Fee”</b>) dari hasil keuntungan yang diterima oleh Penerbit dan/atau Pemodal sebelum pajak, yang akan dikenakan pada saat pembagian dividen dan/atau profit dilakukan. Biaya manajemen ini merupakan biaya atas pengelolaan dan administrasi kegiatan usaha/proyek Penerbit, termasuk namun tidak terbatas pada penyampaian laporan keuangan dan/atau pembagian hasil usaha/proyek Penerbit (pembagian dividen, bunga kupon dan/atau bagi hasil) yang dilakukan oleh Penyelenggara kepada Pemodal;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "baseline" }}>C. </td>
                                        <td colSpan="2">Penerbit dengan ini menyatakan dan menjamin hal-hal sebagai berikut:</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>1. </td>
                                        <td>Penerbit menyatakan dan menjamin bahwa Penerbit adalah badan hukum atau badan usaha lainnya yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan Negara Kesatuan Republik Indonesia, bahwa Penerbit bukan termasuk dari: a. Badan usaha yang dikendalikan baik langsung maupun tidak langsung oleh suatu kelompok usaha atau konglomerasi; b. Perusahaan terbuka atau anak perusahaan terbuka; dan c. Badan usaha dengan kekayaan bersih lebih dari Rp 10.000.000.000 (Sepuluh Miliar Rupiah), tidak termasuk tanah dan bangunan tempat usaha.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>2. </td>
                                        <td>Penerbit dalam menjalankan kegiatan proyek/usahanya telah memiliki setiap perizinan yang dipersyaratkan dan perizinan tersebut, sampai ini masih berlaku dan/atau tidak sedang ditangguhkan oleh pihak yang berwenang.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>3. </td>
                                        <td>Penerbit dalam menjalankan kegiatan proyek/usahanya tidak melanggar hukum dan perundang-undangan yang berlaku pada Negara Kesatuan Republik Indonesia, termasuk namun tidak terbatas pada pelanggaran terhadap hak atas kekayaan intelektual dari pihak ketiga lainnya.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>4. </td>
                                        <td>Penerbit dengan ini menyatakan bertanggung jawab penuh terhadap kualitas, akurasi dan kelengkapan informasi yang disediakan oleh Penerbit kepada Penyelenggara dan menjadi acuan bagi Penyelenggara dalam memberikan dan/atau menyajikan data-data dan informasi-informasi terkait dengan kegiatan usaha/proyek Penyelenggara melalui layanan urun dana yang diselenggarakan oleh Penyelenggara dan yang menjadi acuan Pemodal dalam memeriksa, menganalisa dan mengevaluasi prospektus, data, informasi maupun kelengkapan dokumen dari usaha/proyek Penerbit serta dalam menentukan pilihan investasinya. Dan karenanya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan kualitas, akurasi dan kelengkapan informasi yang mungkin akan timbul dan/atau mungkin akan terjadi terhadap setiap data dan prospektus kegiatan operasional, keuntungan usaha maupun risiko kegiatan operasional Penerbit yang disajikan dan/atau diinformasikan oleh Penyelenggara kepada Pemodal melalui layanan urun dana melalui penawaran efek berbasis teknologi.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>5. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin bahwa penawaran efek melalui layan urun dana yang diselenggarakan oleh Penyelenggara, tidak melanggar ketentuan sebagaimana termuat dalam anggaran dasar Penerbit maupun melanggar dan/atau bertentangan dengan perjanjian apapun antara Penerbit dengan pihak ketiga lainnya.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>6. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin bahwa Penerbit wajib menyelesaikan setiap permasalahan yang timbul terkait dengan pendanaan melalui layanan urun dana, baik dengan Penyelenggara, Pemodal, pihak ketiga lainnya maupun pihak berwenang.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>7. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin bahwa laporan keuangan yang diserahkan kepada Penyelenggara untuk didistribusikan kepada Pemodal adalah benar dan akurat, tidak ada satupun data maupun informasi yang tidak disampaikan ataupun disembunyikan atau dengan perkataan lain tidak disajikan dan/atau diserahkan kepada Penyelenggara.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>8. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin tidak akan menjual, menggadaikan dan/atau dengan cara lain mengalihkan kegiatan usaha/proyek maupun aset Penerbit yang dikelolanya dan terhadap usaha/proyek dan/atau setiap aset yang dimiliki Penerbit tidak sedang dalam sengketa baik perdata maupun pidana dan/atau tidak sedang dikenakan sita eksekusi maupun sita jaminan serta tidak sedang digadaikan dan/atau tidak sedang dibebankan suatu hak tanggungan atau dengan cara lainnya dialihkan kepada pihak ketiga lainnya.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>9. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin bahwa Penerbit memiliki kemampuan untuk melakukan pengelolaan atas usaha/proyek dari Penerbit, memiliki sumber daya manusia yang cakap, handal dan berkemampuan untuk menjalankan dan mengoperasikan kegiatan usaha dari Penerbit. Dalam hal terjadi kekurangan sumber daya manusia dalam menjalankan dan mengoperasikan kegiatan usaha/proyek Penerbit, Penerbit akan berusaha dan berupaya dengan kemampuan terbaiknya untuk mengatasi kekurangan sumber daya manusia yang diperlukan dalam menjalankan dan mengoperasikan kegiatan usaha/proyek Penerbit yang dibiayai melalui layanan urun dana yang diselenggarakan oleh Penyelenggara. Selanjutnya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan kemampuan penerbit dalam melakukan pengelolaan usaha/proyek Penerbit dan kekurangan sumber daya manusia yang diperlukan dalam menjalankan dan mengoperasikan kegiatan usaha/proyek Penerbit.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>10. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin bahwa Penerbit bertanggung jawab sepenuhnya terhadap setiap kerusakan, kerugian maupun kehilangan nyawa dari karyawannya maupun pihak ketiga lainnya, terkait dengan kegiatan usaha/proyek yang dijalankan oleh Penerbit. Selanjutnya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan setiap kerusakan, kerugian maupun kehilangan nyawa dari karyawannya maupun pihak ketiga lainnya sehubungan dengan pelaksanaan kegiatan usaha oleh Penerbit.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>11. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin tidak akan melakukan penyimpangan atas penggunaan setiap dana Pemodal dan setiap dana dari Pemodal yang diserahkan melalui Penyelenggara, akan digunakan sesuai dengan peruntukan dan penggunaannya sesuai dengan apa yang disampaikan dalam prospektus yang diberikan dan disajikan oleh Penyelenggara melalui layanan urun dana. Selanjutnya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan setiap penyimpangan dan/atau penggunaan dana Pemodal.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>12. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin tidak sedang dalam masa penawaran Efek pada penyelenggara lain atau tidak memiliki kewajiban dengan penyelenggara lain.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>13. </td>
                                        <td>Penerbit dengan ini menyatakan dan menjamin bersedia untuk menandatangani perjanjian-perjanjian lain terkait dan sehubungan dengan kegiatan layanan urun dana dan/atau penghimpunan dana melalui Penyelenggara, termasuk namun tidak terbatas pada perjanjian penyelenggara dan penerbit, perjanjian sewa lahan dan/atau tempat usaha/proyek, Surat Perintah Kerja (SPK) maupun perjanjian-perjanjian turunan lainnya dari layanan urun dana yang diselenggarakan oleh Penyelenggara. Selanjutnya, Penerbit dengan ini menyatakan membebaskan Penyelenggara dari setiap klaim, tuntutan, gugatan dari Pemodal dan/atau pihak ketiga lainnya terkait dengan belum ditandatanganinya perjanjian-perjanjian turunan lainnya terkait dan sehubungan dengan kegiatan layanan urun dana dan/atau penghimpunan dana melalui Penyelenggara.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p><b>7. Biaya-biaya</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Untuk setiap nominal nilai investasi yang disetorkan Pengguna, akan dikenakan biaya penggunaan layanan urun dana berbasis teknologi informasi(selanjutnya disebut <b>“<i>platform fee</i>”</b>) sebesar 5% (lima persen) dari keseluruhan nilai investasi yang disetorkan oleh Pemodal. Biaya <i>platform fee</i> tersebut akan ditransfer secara otomatis ke rekening PT Kerjasama Untuk Negeri sebagai biaya penggunaan layanan urun dana.</p>
                            <p>Seluruh profit yang diterima oleh Pemodal, yaitu berupa dividen maupun kupon sebagaimana disebut dalam poin 3 di atas, akan dipotong <i>management fee</i> sebesar 5% (lima persen) secara otomatis melalui Saldo Efek.</p>
                            <p>Penerbit dan Pemodal setuju dan membebaskan Kerjasama.co.id dari seluruh pajak yang timbul atas penyetoran dana, pembagian dividen, pembagian kupon dan transaksi apapun lainnya kecuali apabila hal tersebut telah diatur oleh peraturan perundang-undangan yang berlaku.</p>
                        </div>

                        <p><b>8. Risiko</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Pemodal mengakui dan menyadari bahwa setiap usaha memiliki risikonya masing-masing. Untuk itu, dengan berinvestasi melalui Kerjasama.co.id, Pemodal dengan ini mengerti akan segala risiko yang dapat terjadi di kemudian hari, diantaranya meliputi risiko: 1. usaha; 2. investasi; 3. likuiditas; 4. kelangkaan pembagian dividen, 5. dilusi kepemilikan saham; 6. kegagalan sistem elektronik; dan 7. gagal bayar atas Efek bersifat utang{/* atau Sukuk*/};</p>
                            <p>Atas resiko yang dijelaskan di atas, Pemodal dengan ini membebaskan Kerjasama.co.id dari segala klaim, tuntutan, ganti rugi yang terkait dengan risiko yang mungkin akan timbul dan atau mungkin akan terjadi atas kegiatan usaha/proyek Penerbit, termasuk namun tidak terbatas pada kegagalan kegiatan usaha Penerbit untuk memperoleh penghasilan dan/atau profit maupun Penerbit dinyatakan bangkrut maupun pailit yang dapat terjadi dikemudian hari.</p>
                        </div>

                        <p><b>9. Privasi dan Keamanan</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Kerjasama.co.id menjamin keamanan data pribadi Penerbit dan Pemodal dalam menggunakan dan mengakses layanan urun dana berbasis teknologi informasi pada Situs https://kerjasama.co.id.</p>
                            <p>Semua informasi pribadi dari Penerbit maupun Pemodal akan dilindungi sepenuhnya kerahasiaannya. Kerahasiaan informasi yang anda masukkan dalam Situs https://kerjasama.co.id akan kami jaga kerahasian nya sebaik-baiknya untuk kepentingan Penerbit maupun Pemodal.</p>
                            <p>Kerjasama.co.id berhak untuk mengubah dan memperbarui syarat dan ketentuan penggunaan Situs https://kerjasama.co.id ini sewaktu-waktu tanpa pemberitahuan sebelumnya kepada Penerbit dan/atau Pemodal. Kerjasama.co.id akan memberitahukan setiap perubahan-perubahan pada Situs https://kerjasama.co.id dan akan mengirimkan pemberitahuan melalui email jika dirasa dan dipandang perlu. Perubahan dari syarat dan ketentuan akan berlaku setelah dimuat pada dalam Situs https://kerjasama.co.id.</p>
                        </div>

                        <p><b>10. Akun Anda</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Anda dapat mendaftarkan diri menjadi anggota Situs https://kerjasama.co.id dengan mengisi formulir pendaftaran. Setelah pendaftaran disetujui dan memenuhi syarat dan ketentuan pembukaan akun, Pemodal dan Penerbit dapat mengunggah konten, mengajukan pendanaan usaha ataupun menjadi investor pada layanan urun dana yang diselenggarakan oleh Kerjasama.co.id.</p>
                            <p>Untuk mendaftar, anda wajib memberikan alamat email aktif anda. Alamat email ini akan mempermudah kami melakukan verifikasi identitas anda pada kunjungan berikutnya. Apabila anda mendaftar dengan menggunakan alamat email fiktif atau alamat email milik orang lain, Kerjasama.co.id dapat menutup account anda tanpa pemberitahuan sebelumnya secara permanen. Selain itu anda wajib menyediakan password yang akan digunakan untuk mengakses materi dan layanan Situs https://kerjasama.co.id.</p>
                            <p>Setelah anda mengisi data profil pada Situs https://kerjasama.co.id serta mengunggah dokumen kelengkapannya, kerjasama.co.id akan melakukan verifikasi akun anda dalam waktu 1 x 24 jam.</p>
                            <p>Anda tidak akan dikenakan biaya untuk membuka <i>account</i> pada Situs https://kerjasama.co.id. Akan tetapi, anda akan dikenakan biaya sesuai dengan tarif sebagaimana tersebut dalam Syarat dan Ketentuan umum ini. Segala transaksi yang dilakukan harus melalui Saldo Efek.</p>
                            <p>Jika masih terdapat pertanyaan mengenai layanan urun dana berbasis teknologi informasi yang diselenggarakan oleh Kerjasama.co.id, anda dapat menyampaikan setiap pertanyaan anda melalui <a href="mailto:cs@kerjasama.co.id">cs@kerjasama.co.id.</a></p>
                        </div>

                        <p><b>11. Penghapusan Akun</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Anda dapat menghapus akun anda di Situs https://kerjasama.co.id dengan menghubungi alamat email cs@kerjasama.co.id yang kami sediakan. Untuk membatalkan akun tersebut, anda wajib memberikan informasi dan konfirmasi kepada Kerjasama.co.id bahwa anda adalah pemilik sesungguhnya dari akun tersebut. Akun anda akan dibatalkan setelah Kerjasama.co.id menerima konfirmasi dari anda mengenai otorisasi keabsahan identitas anda.</p>
                            <p>Kerjasama.co.id berhak membatalkan akun anda tanpa pemberitahuan sebelumnya untuk kepentingan Situs https://kerjasama.co.id. Penghapusan akun ini dapat terjadi bila adanya hal-hal termasuk, tetapi tidak terbatas pada:</p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td width='20'></td>
                                        <td style={{ verticalAlign: "baseline" }}>A. </td>
                                        <td>Pelanggaran Syarat dan Ketentuan Umum yang berlaku di Situs https://kerjasama.co.id;</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>B. </td>
                                        <td>Permintaan dari badan hukum atau instansi milik pemerintah;</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>C. </td>
                                        <td>Permintaan dari anda sendiri;</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>D. </td>
                                        <td>Periode ketidakaktifan akun yang cukup lama; dan</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>E. </td>
                                        <td>Kasus penipuan atau penggelapan yang dilakukan oleh anda atau hal-hal yang telah atau berpotensi merugikan pihak lain.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan='3'>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan='3'>Pembatalan akun anda dapat berakibat sebagai berikut:</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>A. </td>
                                        <td>Pembatasan keikutsertaan Pemodal dalam kegiatan layanan urun dana yang dilakukan oleh Penerbit lain melalui Kerjasama.co.id (<i>blacklist</i>).</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>B. </td>
                                        <td><i>Password</i> anda tidak akan berlaku lagi sehingga anda tidak dapat mengakses bagian-bagian situs yang dilindungi dengan <i>password</i>; dan/atau</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>C. </td>
                                        <td>Anda tidak dapat lagi menggunakan layanan Kerjasama.co.id secara permanen.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p><b>12. Hak Kekayaan Intelektual</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Seluruh desain, foto, logo dan gambar yang termuat pada Situs Kerjasama.co.id adalah milik dari PT Kerjasama Untuk Negeri yang dilindungi hukum dan perundang-undangan Negara Kesatuan Republik Indonesia dan tidak diperkenankan untuk menyebarluaskan desain, foto, logo dan gambar baik sebagian maupun secara keseluruhan oleh pihak manapun dan untuk kepentingan apapun, tanpa pemberitahuan dan persetujuan secara tertulis dari Kerjasama.co.id.</p>
                        </div>

                        <p><b>13. Keadaan Kahar (<i>Force Majeure</i>)</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>A. </td>
                                        <td>Para Pihak dibebaskan dari kewajibannya berdasarkan Perjanjian ini dalam hal terjadinya, dan selama terjadinya suatu Keadaan Kahar.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>B. </td>
                                        <td>Yang dimaksud dengan Keadaan Kahar (“<i>force majeure</i>”) adalah suatu kejadian atau peristiwa yang yang terjadi di luar daya upaya manusia dan/atau tidak dapat diduga atau diprediksi sebelumnya dan/atau di luar kendali kekuasaan salah satu dari Para Pihak dan/atau Penerbit untuk mengatasinya, yang mengakibatkan terhambatnya pelaksanaan kewajiban salah satu dari Para Pihak dan/atau Penerbit, termasuk namun tidak terbatas pada:</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ verticalAlign: "baseline" }}>1. </td>
                                                        <td>Kejadian atau peristiwa yang terjadi atas kehendak Tuhan, termasuk namun tidak terbatas pada bencana alam angin topan, angin puting beliung, wabah penyakit, gempa bumi, petir, banjir, tsunami, kebakaran, tanah longsor dan/atau bencana alam lainnya.</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ verticalAlign: "baseline" }}>2. </td>
                                                        <td>Huru-hara, kerusuhan sipil, peperangan, pemberontakan, mogok kerja, sabotase, perbuatan perusakan/penghancuran (vandalisme), embargo, tindakan pemerintah, perubahan peraturan perundang-undangan, gangguan sistem teknologi informasi dan/atau kegagalan sistem serta alih kelola sistem teknologi informasi, baik yang disebabkan oleh penyadapan oleh pihak ketiga maupun bukan dan/atau sebab-sebab serupa lainnya, yang terjadi di luar kekuasaan dan kemampuan Para Pihak dan/atau Penerbit yang menyebabkan Para Pihak dan/atau Penerbit tidak dapat memenuhi kewajibannya berdasarkan Perjanjian ini;</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ verticalAlign: "baseline" }}>3. </td>
                                                        <td>Dalam hal terjadi <i>force majeure</i>, Pihak yang tertimpa dan/atau terdampak oleh <i>force majeure</i>, harus memberitahukan secara tertulis atau melalui surat elektronik (<i>email</i>) kepada Pihak lain yang tidak tertimpa dan/atau terdampak oleh <i>force majeure</i>, selambat-lambatnya 3 (tiga) Hari Kalender sejak terjadinya <i>force majeure</i> dimaksud;</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ verticalAlign: "baseline" }}>4. </td>
                                                        <td>Dalam hal <i>force majeure</i> berlanjut hingga dan/atau berlangsung lebih dari 30 (tiga puluh) Hari Kalender berturut-turut dan/atau berdasarkan besarnya dampak <i>force majeure</i> terhadap pelaksanaan Perjanjian ini, dengan berdasarkan itikad baik, Para pihak dapat melakukan konsultasi dan/atau musyawarah untuk memutuskan kelanjutan dan/atau keberlangsungan dari Perjanjian ini.</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td style={{ verticalAlign: "baseline" }}>5. </td>
                                                        <td>Tidak satupun dari Para Pihak bertanggung jawab atas kerugian yang mungkin timbul dan/atau diderita oleh pihak lainnya dan/atau pihak ketiga lainnya yang disebabkan oleh suatu kegagalan atau keterlambatan pelaksanaan dari Perjanjian ini yang disebabkan oleh <i>force majeure</i>. Namun demikian, Pihak yang tertimpa dan/atau terdampak oleh <i>force majeure</i>, akan melakukan upaya yang sewajarnya untuk memenuhi kewajibannya dan/atau mengurangi kerugian bagi Para Pihak atas terjadinya <i>force majeure</i>;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p><b>14. Kerahasiaan</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>1. </td>
                                        <td>Para Pihak sepakat dan setuju untuk menjaga kerahasiaan dari setiap data dan/atau data-data, catatan-catatan, ringkasan, perjanjian, kontrak, laporan maupun informasi, termasuk namun tidak terbatas pada laporan keuangan dan/atau prospektus dalam format dan bentuk apapun, yang diungkapkan oleh salah satu dari Para Pihak kepada Pihak lainnya dan/atau yang diperoleh berdasarkan Perjanjian ini dan/atau Perjanjian ikutan lainnya (untuk selanjutnya disebut “Informasi Rahasia”);</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>2. </td>
                                        <td>Tidak satupun dari Para Pihak berhak dan/atau berwenang untuk melakukan pengungkapan kepada pihak ketiga, membuat pengumuman kepada khalayak umum dan/atau siaran pers yang berkaitan dengan subjek maupun objek dari perjanjian ini.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>3. </td>
                                        <td>Dalam hal, salah satu dari Para Pihak diwajibkan oleh perintah pengadilan, penetapan pengadilan, ketentuan hukum, peraturan perundang-undangan, peraturan dari badan dan/atau instansi pemerintah yang berwenang, termasuk namun tidak terbatas pada Otoritas Jasa Keuangan Republik Indonesia, untuk mengungkapkan Informasi Rahasia terkait dengan Perjanjian ini, Pihak yang terkena kewajiban tersebut wajib untuk melakukan pemberitahuan secara tertulis selambat-lambatnya 3 (tiga) Hari Kerja sebelumnya kepada Pihak lainnya. Selanjutnya, ketika pengungkapan Informasi Rahasia akan dilakukan oleh Pemodal, Pemodal wajib untuk meminta persetujuan tertulis terlebih dahulu dari Penyelenggara dan Penerbit sebelum pengungkapan dilakukan dan Penyelenggara tidak akan menunda dalam pemberian persetujuan tertulisnya tanpa alasan yang wajar.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>4. </td>
                                        <td>Kewajiban untuk menjaga kerahasiaan dari Informasi Rahasia dan/atau Perjanjian ini sebagaimana diatur dalam dalam Pasal ini akan tetap dan terus berlaku sekurang-kurangnya sampai dengan tiga (3) tahun setelah Perjanjian ini berakhir.</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ verticalAlign: "baseline" }}>5. </td>
                                        <td>Setiap saat, apabila diminta secara tertulis oleh pemilik Informasi Rahasia, seluruh Informasi Rahasia wajib dikembalikan oleh pihak yang memegang dan/atau menguasai Informasi Rahasia dimaksud kepada pemilik Informasi Rahasia. Akan tetapi, terhadap Informasi Rahasia yang tidak diminta untuk dikembalikan oleh pemilik Informasi Rahasia, wajib dimusnahkan dalam waktu selambat-lambatnya 7 (tujuh) hari setelah pemutusan dan/atau pengakhiran Perjanjian ini, dan pemegang dan/atau pihak yang menguasai Informasi Rahasia tersebut akan memberikan konfirmasi dan/atau pernyataan tertulis yang menyatakan bahwa pemusnahan tersebut telah dilakukan.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p><b>15. Penyelesaian dan Hukum</b></p>
                        <div style={{ marginLeft: 20, marginBottom: 20 }}>
                            <p>Syarat dan Ketentuan umum yang termuat pada Situs kerjasama.co.id diatur oleh dan diartikan secara keseluruhan sesuai dengan Hukum Negara Kesatuan Republik Indonesia. Para Pihak dengan ini secara tegas menyetujui untuk melaksanakan kewajiban mereka masing-masing sesuai dengan peraturan perundang-undangan yang berlaku saat ini dan/atau di kemudian hari.</p>
                            <p>Segala perselisihan yang timbul dari pelaksanaan layanan urun dana maupun Syarat dan Ketentuan ini akan diselesaikan secara musyawarah untuk mufakat, apabila penyelesaian Sengketa secara musyawarah untuk mufakat tidak tercapai dalam waktu selambat-lambatnya 30 (tiga puluh) Hari Kalender, Para Pihak sepakat untuk menyelesaikan Sengketa yang timbul melalui Badan Arbitrase Nasional Indonesia (“BANI”) berdasarkan dan sesuai dengan ketentuan dan peraturan hukum Republik Indonesia serta aturan penyelesaian Sengketa yang yang berlaku pada BANI.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TermsCondition;
